import { useEffect } from "react"
import getCart from "../utilities/get-cart"

export default function useCartIdEffect(value) {
    const { cartId } = value

    useEffect(() => {
        if (!cartId) return
        const e = { type: 'useEffect' }
        getCart(e, value)
    }, [cartId])
}