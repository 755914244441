// FRAMEWORK
import React from 'react'

// APP
import AppContext from '../context/app'
import Navbar from '../components/navbar'
import InMusic from '../components/inmusic'
import Footer from '../components/footer'

// HOOKS
import useGetAppValue from '../hooks/useGetAppValue'
import useWindowEventListenersEffect from '../hooks/useWindowEventListenersEffect'
import useCountryEffect from '../hooks/useCountryEffect'
import useCartIdEffect from '../hooks/useCartIdEffect'

// CSS
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/bootstrap-overrides.scss'
import '../styles/global.scss'

export default function MainLayout({ children }) {
    const value = useGetAppValue()
    useWindowEventListenersEffect(value)
    useCountryEffect(value)
    useCartIdEffect(value)

    return (
        <AppContext.Provider value={value}>
            <Navbar />
            <main>
                {children}
            </main>
            <footer>
                <InMusic />
                <Footer />
            </footer>
        </AppContext.Provider>
    )
}