exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-beta-download-js": () => import("./../../../src/pages/beta-download.js" /* webpackChunkName: "component---src-pages-beta-download-js" */),
  "component---src-pages-beta-signup-js": () => import("./../../../src/pages/beta-signup.js" /* webpackChunkName: "component---src-pages-beta-signup-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-effects-air-creative-fx-collection-2-js": () => import("./../../../src/pages/effects/air-creative-fx-collection-2.js" /* webpackChunkName: "component---src-pages-effects-air-creative-fx-collection-2-js" */),
  "component---src-pages-effects-air-soft-clipper-js": () => import("./../../../src/pages/effects/air-soft-clipper.js" /* webpackChunkName: "component---src-pages-effects-air-soft-clipper-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-sale-js": () => import("./../../../src/pages/sale.js" /* webpackChunkName: "component---src-pages-sale-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-subscriptions-js": () => import("./../../../src/pages/subscriptions.js" /* webpackChunkName: "component---src-pages-subscriptions-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-virtual-instruments-sub-factory-js": () => import("./../../../src/pages/virtual-instruments/sub-factory.js" /* webpackChunkName: "component---src-pages-virtual-instruments-sub-factory-js" */),
  "component---src-pages-virtual-instruments-timewarp-2600-js": () => import("./../../../src/pages/virtual-instruments/timewarp-2600.js" /* webpackChunkName: "component---src-pages-virtual-instruments-timewarp-2600-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

