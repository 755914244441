import { useEffect } from "react"
import Cookies from 'js-cookie'
import getCart from '../utilities/get-cart'
import getCartId from '../utilities/get-cart-id'

export default function useCountryEffect(value) {
    const { country, setCartId, cart } = value

    useEffect(() => {
        if (!country) return

        const eswCartId = Cookies.get('eswCartId')
        eswCartId ? setCartId(eswCartId.replace('cart_', '')) : getCartId(value)

        if (cart?.total_count) getCart({ type: 'useEffect' }, value)
    }, [country])
}