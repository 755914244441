const ls = require('local-storage')

exports.onInitialClientRender = () => {
    // DOCUMENTATION
    // https://dev.maxmind.com/geoip/geolocate-an-ip/client-side-javascript

    function onSuccess(response) {
        const event = new CustomEvent('MAXMIND_GEO_IP', { detail: JSON.stringify(response) })
        window.dispatchEvent(event)
    }

    function onError(error) {
        console.log(error)
    }

    // This parameter is reserved for future use. 
    // There are no options at this time.
    const options = {}

    const iso = ls.get('SELECTED_COUNTRY_ISO')
    iso ? onSuccess({ country: { iso_code: iso } }) : window.geoip2.country(onSuccess, onError, options)
}

exports.onRouteUpdate = ({ location }) => {
    window.dispatchEvent(new Event('NAVIGATE'))

    const { hash } = location
    const el = hash ? document.querySelector(hash) : null
    if (el) window.requestAnimationFrame(() => window.scrollTo(0, el.offsetTop))
}

exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition, }) => {
    const savedScrollPosition = getSavedScrollPosition(location)
    window.requestAnimationFrame(() => window.scrollTo(...savedScrollPosition))
    return false
}
