import { PIMCORE_URL, ENDPOINTS } from "../../gatsby-node/constants"
import Cookies from 'js-cookie'
import getCartId from './get-cart-id'

export default function getCart(e, value) {
    const { cartId, country, setCart, setShowCart, loadingCart, setLoadingCart } = value
    if (!loadingCart) setLoadingCart(true)

    const session_cart_id = cartId
    const iso = country.node.country_iso_code

    const options = {
        method: 'post',
        body: JSON.stringify({ session_cart_id })
    }

    fetch(`${PIMCORE_URL}${ENDPOINTS.GET_CART(iso)}`, options)
        .then(r => r.json())
        .then(d => {
            const { success, data, message, isOrderPlaced } = d
            if (message) console.log(message)
            if (isOrderPlaced) {
                Cookies.remove('eswCartId')
                getCartId(value)
            }

            if (data) {
                setCart(data)
                if (e.type !== 'useEffect') setShowCart(true)
            } else {
                setCart({})
                setShowCart(false)
            }
            
            setLoadingCart(false)
        })
}
